import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Auth from '../../services/auth'
import { useTranslation } from 'react-i18next'
import PhoneIcon from '@material-ui/icons/Phone'
import LockIcon from '@material-ui/icons/Lock'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import RestorePassword from './reg_tabs/RestorePassword'
import 'react-toastify/dist/ReactToastify.css'
import Registration from '../login/reg_tabs/TabPrimary'
import Input from '../../components/Input/index'
import Company from '../login/reg_tabs/Company'
import INN from '../../components/custom/INN/INN'
import { authActionTypes } from '../../redux/actions/authActions/authActionTypes'
import Regstratsiya from '../../views/store/reseacher'
import { ComputerSvg, LogoIcon } from '../../components/svg'
import Checkbox from '@material-ui/core/Checkbox'
import Img from '../../assets/icons/Group.png'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import LoginForm from './LoginForm'
import ConfirmCode from './reg_tabs/ConfirmCode'
// import ContactUsModal from '../ContactUsModal/ContactUsModal'

function App() {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.auth)
  // const history = useHistory()

  const [username, setUsername] = useState('')
  const [seePasswordIcon, setSeePassword] = useState(true)
  const [authPasscode, setAuthPasscode] = useState({
    passcode_token: '',
    passcode: '',
  })
  const [userData, setUserData] = useState({
    owner_name: '',
    email: '',
    phone: '',
    company_name: '',
    inn: '',
    password: '',
  })
  const [tabActive, setActiveTab] = useState('login')
  const [password, setPassword] = useState('')
  // const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false)
  const [checkINN, setCheckINN] = useState(false)
  const [error, setError] = useState(false)
  const [buttonDisable, setButtonDisebl] = useState(true)
  const [popapOpen, setpopapOpen] = useState(false)

  useEffect(() => {
    console.log(state)
  }, [state])
  const handelSetReduxValue = () => {
    // dispatch())
  }
  const handleValidation = () => {
    let name = username
    let pass = password
    let errors = {}
    let isValid = true

    //Name
    if (name === '') {
      isValid = false
      errors['name'] = 'Это поле обязательно к заполнению'
    } else if (typeof name !== undefined) {
      if (!name.match(/^[a-zA-Z]+$/)) {
        isValid = false
        errors['name'] = 'Используйте только буквы'
      }
    }

    //Password
    if (pass === '') {
      isValid = false
      errors['password'] = 'Это поле обязательно к заполнению'
    }

    setError(errors)
    return isValid
  }
  const confirmItemToken = () => {
    setCheckINN(false)
    // console.log(Regstratsiya)
    Regstratsiya.itemTokenSet(authPasscode).then((res) => {
      // console.log(res)
      // Notifceation.Toast('сукцесс', 'success')
      localStorage.setItem('token', res.data.token.access_token)
      localStorage.setItem('refresh_token', res.data.token.refresh_token)
      history.push('/home/dashboard/teamsList')
      // dispatch({type: payload: {}})
    })
  }
  const handleCompany = (e) => {
    // console.log(e)
    if (true) {
      let data = {
        company_name: userData.company_name,
        email: userData.email,
        inn: userData.inn,
        owner_name: userData.fullname,
        password: userData.password,
        phone: userData.phone,
      }

      // console.log('hello')
      Auth.RegistratsiyaCompany(data).then((res) => {
        if (res.code === 200) {
          toast.success(t(`Успешно!`), {
            className: 'bg-green-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 2000,
          })
          setCheckINN(true)
          setAuthPasscode((old) => ({
            ...old,
            passcode_token: res.data.passcode_token,
          }))
        }
      })
    }
  }
  const handleRegistration = () => {
    setActiveTab('registration')
  }

  const handleRestorePassword = () => {
    setActiveTab('restorePassword')
  }
  const handleEmail = () => {
    setActiveTab(() => ({
      email: true,
      regstratsiya: false,
      login: false,
      companya: false,
    }))
  }

  return (
    <div className='font-body flex h-screen'>
      <div
        className='flex font-body justify-center w-1/2'
        style={{
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(0, 103, 244, 0.45) 0%, #0067F4 100%)',
        }}
      >
        {/* <div className='absolute top-5 left-5'>
          <LogoIcon />
        </div> */}

        <div className='w=1/2  my-40  text-2xl rounded-lg '>
          <div className='flex justify-center p-5 -ml-16  lg:h-96 md:h-96'>
            <img src={Img} alt='' />
          </div>
        </div>
      </div>
      <div className='w-1/2 flex flex-col pt-24 relative'>
        <div className='w-5/6 mx-auto '>
          {tabActive === 'login' && (
            <LoginForm
              seePasswordIcon={seePasswordIcon}
              setActiveTab={setActiveTab}
              setPassword={setPassword}
              setSeePassword={setSeePassword}
              history={history}
              dispatch={dispatch}
              t={t}
              toast={toast}
            />
          )}
          {tabActive === 'registration' && (
            <Registration
              className='w-full'
              setUserData={setUserData}
              value={userData}
              setActiveTab={setActiveTab}
              toast={toast}
              userData={userData}
            />
          )}
          {tabActive === 'company' && (
            <Company
              setUserData={setUserData}
              value={userData}
              setActiveTab={setActiveTab}
              seePasswordIcon={seePasswordIcon}
              setSeePassword={setSeePassword}
              history={history}
              dispatch={dispatch}
              t={t}
              Auth={Auth}
              authActionTypes={authActionTypes}
              toast={toast}
              userData={userData}
              setAuthPasscode={setAuthPasscode}
            />
          )}
          {tabActive === 'restorePassword' && (
            <RestorePassword
              setUserData={setUserData}
              value={userData}
              setActiveTab={setActiveTab}
              handleCompany={handleCompany}
            />
          )}
          {tabActive === 'confirmCode' && (
            <ConfirmCode
              authPasscode={authPasscode}
              setUserData={setUserData}
              value={userData}
              setActiveTab={setActiveTab}
              handleCompany={handleCompany}
            />
          )}
        </div>

        <INN
          authPasscode={authPasscode}
          setAuthPasscode={setAuthPasscode}
          checkINN={checkINN}
          confirmItemToken={confirmItemToken}
          setCheckINN={setCheckINN}
          setOpen={setOpen}
        />

        <div className='absolute bottom-5 left-14'>
          <span className='text-grey'>
            Copyright © URecruit. Все права защищены
          </span>
          {/* <ContactUsModal /> */}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}

export default App
